
import React from 'react';
import Contact from '../components/Contact';
import Navbar from '../components/Navbar';
import Work from '../components/Work';
import '../resources/css/Works.scss';
import {Helmet} from "react-helmet";

function isMobile() {
	try{ document.createEvent("TouchEvent"); return true; }
	catch(e){ return false; }
}

class Works extends React.Component {
    
    componentDidMount(){
        document.title = 'Abdelatheem Maraqa Full Stack (Websites, Apps) Developer - Works';
    }
    
    render(){
        return (
            <div className="App">
                    <Helmet>
                        <meta charset="utf-8" />
                        <link rel="icon" href="head.svg" />
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <meta name="theme-color" content="#000000" />
                        <meta name="description" content="Abdelatheem Maraqa || Full Stack Frontend & Backend Developer (Websites, Mobile Apps, ReactJS, React Native, PHP Laravel, SEO) +2 years of experience - Works" />
                        <link rel="apple-touch-icon" href="/head.svg" />
                        <title>Abd Maraqa || Full Stack Web & Mobile Apps Developer - Works</title>
                        <link rel="canonical" href="https://abd-maraqa.com/" />
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w==" crossorigin="anonymous" referrerpolicy="no-referrer"></link>
                    </Helmet>
                    <Navbar />
                    <section className="portfolio section-padding" data-scroll-index="3">
                        <div className="container">
                            <div className="row">
                                <div className="section-head full-width">
                                    <h4 className="title">My Works</h4>
                                </div>
                            </div>

                            <div className="content">
                                {isMobile() ?
                                    <p className="description text-center">Click a project to see details.</p>
                                :
                                    <p className="description text-center">Hover over a project to see details.</p>
                                }
                                <Work 
                                    class="alwaseet"  title="Al Waseet" 
                                    desc="Al Waseet is one of the most popular classifieds websites that allow users to easily search for their needs or offer them for sale on the Internet." 
                                    link="https://kw.waseet.net/en/"  langs="Bootstrap . SASS . Yii2" icons="web"
                                />

                                <Work 
                                    class="khazaneh"  title="Khazaneh" 
                                    desc="A fashion app that suggest on people what to wear every day from there own clothes based to occaion or the type of clothes they want to wear." 
                                    link=""  langs="React Native . Redux . Laravel" icons="app"
                                />

                                <Work 
                                    class="yashfeen"  title="Yashfeen" 
                                    desc="A medical E-commerce website for selling medical & health care products online." 
                                    link="https://yashfeen.online/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="tacticat"  title="Tacticat" 
                                    desc="An application and website for the latest football news, results and tactics for the matches of the top five European leagues." 
                                    link="https://tacticalfootball.net/"  langs="React Native . Redux . Bootstrap . Laravel" icons="webapp"
                                />

                                <Work 
                                    class="washup"  title="Washup" 
                                    desc="A laundry app where users subscribe to a package in exchange for washing a certain number of items per month." 
                                    link=""  langs="React Native . Redux . Laravel" icons="app"
                                />

                                <Work 
                                    class="greenpower"  title="Jordan Green Power" 
                                    desc="The official site of the Energy and Environmental Sustainability Unit in the Jordan Chamber of Industry." 
                                    link="https://jordangreenpower.com/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="asmaa"  title="Asmaa Kayyali" 
                                    desc="A website for the enterpreneurship trainer, consultant & speaker Asmaa Al Kayyali to introduce and communicate with her." 
                                    link="https://asmakayyali.com/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="ominus"  title="O-minus Marketing" 
                                    desc="A website for O-minus Marketing company to introduce it and the services provided by the company in addition to a tool for evaluating, analyzing and improving the work." 
                                    link="https://ominus.marketing/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="vbc"  title="VBC VBusinessCenter" 
                                    desc="A business center for renting furnished offices, meeting rooms, virtual offices, dedicated offices and shared offices with all related services." 
                                    link="https://vbc.jo/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="himitso"  title="Himitso" 
                                    desc="A website for a Jordanian startup specialized in providing Educational Kits built on the method of 'learning by playing'." 
                                    link="https://www.himitso.com/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="westanbul"  title="WEstanbul" 
                                    desc="A website for one of the most important consulting company in Turkey that provides business and investment services and consultancy for the investors and businessmen all around the world." 
                                    link="https://westanbul.com.tr/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="pharmacy"  title="Pharmacy jo" 
                                    desc="A website that helps users to search for pharmacies in any region in Jordan in addition to their numbers and locations on the map, as it contains information for all pharmacies in Jordan." 
                                    link="https://pharmacy.jo/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="impact"  title="Impact MENA" 
                                    desc="A website for a Regional Consulting and Advisory firm established to deal with the local & regional Challenges in the Middle East and North Africa." 
                                    link="https://impactmena.com/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="rr"  title="RR" 
                                    desc="An application for horse competitions in America to follow the results, participants and their statistics." 
                                    link=""  langs="React Native . Redux . Bootstrap . Laravel" icons="webapp"
                                />

                                <Work 
                                    class="mycp"  title="MYCP" 
                                    desc="A application to follow the results of various sports such as American football, basketball, etc., and to follow up the participants and their statistics." 
                                    link=""  langs="React Native . Redux . Bootstrap . Laravel" icons="webapp"
                                />

                                <Work 
                                    class="tagcy"  title="Tagcy" 
                                    desc="A website for publishing events in various genres, whether they are musical, educational, etc., in Germany and outside Germany." 
                                    link="https://tagcy.com/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />

                                <Work 
                                    class="topics"  title="Topics Dict" 
                                    desc="A website for publishing educational, cultural, sports and other articles in several languages." 
                                    link="https://ominus.marketing/topics/"  langs="Bootstrap . SASS . Laravel" icons="web"
                                />
                            </div>
                        </div>
                    </section>

                <Contact />
                {/* <!-- ========== Start Footer --> */}
                <footer className="footer">
                    <p>Copyright &copy; Abdelatheem Maraqa And Made With Love All Rights Reserved.</p>
                </footer>
                {/* <!-- End Footer ============== --> */}
            </div>
        );
    }
}

export default Works;