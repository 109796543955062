
import React from 'react';
// import Languages from "./langauges.tsx";
import '../resources/css/menu.scss';

function About() {
    return (
    	<section className="hero section-padding" id="about" data-scroll-index="1">
    		<div className="container">
    			<div className="row">
    				<div className="col-lg-5">
    					<div className="portrait" id="portrait">
							<div className="fill cover-img"></div>
							<div className="fill z-10 hover">
								<div className="fill cover-img hover-img"></div>
							</div>
						</div>
    				</div>
    				<div className="col-lg-7">
    					<div className="content">
							<h4 className="title title-left">I Am a Full Stack Developer</h4>
    						<p>I am <span>Abdelatheem Maraqa</span> a full stack web and mobile applications developer currently living in Amman, Jordan.</p>
    						<p>I really enjoy solving problems as well as making things pretty and easy to use. I
							can't stop learning new things; the more, the better. I'm also a hardworker, patient and always looking to improve myself professionally.
							My hobbies away from my field of work are almost all kinds of sports, especially football and I'm a big fan of F.C.Barcelona!</p>
							
							<div className="col-md-12 text-center">
								<span className="buton text-center">
									<a href="/#contact" front="Hire Me" back="&#xf1d8;"> </a>
								</span>
								<span className="buton buton-bord text-center">
									<a href="Abdelatheem Maraqa (Full Stack Developer).pdf" front="Download C.V" back="&#xf019;" download="Abdelatheem Maraqa (Full Stack Developer).pdf"> </a>
								</span>
							</div>

							<div className="social-container">
								<ul className="social-icons">
									<li className="tool tool-g" data-tip="Call Me">
										<a href="tel: +962795413503" target="_blank" rel="noopener noreferrer" className="whats">
											<i className="fa fa-phone"></i>
										</a>
									</li>
									<li className="tool tool-b" data-tip="My Linkedin">
										<a href="https://www.linkedin.com/in/abdel-atheem-maraqa-346680187/" target="_blank" rel="noopener noreferrer">
											<i className="fab fa-linkedin-in"></i>
										</a>
									</li>

									<li className="tool" data-tip="Email me">
										<a href="mailto: me@abd-maraqa.com" target="_blank" rel="noopener noreferrer" className="google">
											<i className="fab fa-google-plus-g"></i> 
										</a>
									</li>
									
									<li className="tool tool-b"  data-tip="My Facebook">
										<a href="https://www.facebook.com/abood.anas.712/" target="_blank" rel="noopener noreferrer">
											<i className="fab fa-facebook-f"></i>
										</a>
									</li>

									<li className="tool tool-g" data-tip="My Whatsapp">
										<a href="https://api.whatsapp.com/send?phone=+962795413503" target="_blank" rel="noopener noreferrer" className="whats">
											<i className="fab fa-whatsapp"></i>
										</a>
									</li>
														
								</ul>
							</div>
    					</div>
    				</div>
    			</div>
            {/* <Languages /> */}
    		</div>
        </section>
    );
}

export default About;