import React from 'react';
import { Link } from "react-router-dom";
import Contact from '../components/Contact';
import Navbar from '../components/Navbar';
import * as THREE from 'three';
import {Helmet} from "react-helmet";

function isMobile() {
	try{ document.createEvent("TouchEvent"); return true; }
	catch(e){ return false; }
}

class NotFound extends React.Component  {

    componentDidMount(){
        document.title = 'Abdelatheem Maraqa Full Stack (Websites, Apps) Developer - 404 Not Found';
        function main() {
            const canvas = document.getElementById('bola');
            const renderer = new THREE.WebGLRenderer({canvas, alpha: true});
          
            const fov = 40;
            const aspect = 2;  // the canvas default
            const near = 0.1;
            const far = 1000;
            const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
            
            if(isMobile()){
              camera.position.z = 30;
              camera.position.x = 7;
            } else {
              camera.position.z = 20;
            }
            
          
            const scene = new THREE.Scene();
            scene.background = null;
          
            {
              const color = 0xFFFFFF;
              const intensity = 1;
              const light = new THREE.DirectionalLight(color, intensity);
              light.position.set(-1, 2, 4);
              scene.add(light);
            }
            {
              const color = 0xFFFFFF;
              const intensity = 1;
              const light = new THREE.DirectionalLight(color, intensity);
              light.position.set(1, -2, -4);
              scene.add(light);
            }
          
            const objects = [];
            const spread = 15;
          
            function addObject(x, y, obj) {
              obj.position.x = x * spread;
              obj.position.y = y * spread;
          
              scene.add(obj);
              objects.push(obj);
            }
          
            function createMaterial() {
              const material = new THREE.MeshPhongMaterial({
                side: THREE.DoubleSide,
              });
          
              const hue = Math.random();
              const saturation = 1;
              const luminance = .5;
              material.color.setHSL(hue, saturation, luminance);

              return material;
            }

            function addSolidGeometry(x, y, geometry) {
              const mesh = new THREE.Mesh(geometry, createMaterial());
              addObject(x, y, mesh);
            }
          
            {
                const radius = 7;
                const detail  = 2;
                addSolidGeometry(0, 0, new THREE.OctahedronGeometry(radius, detail));
            }
          
            function resizeRendererToDisplaySize(renderer) {
              const canvas = renderer.domElement;
              const width = canvas.clientWidth;
              const height = canvas.clientHeight;
              const needResize = canvas.width !== width || canvas.height !== height;
              if (needResize) {
                renderer.setSize(width, height, false);
              }
              return needResize;
            }
          
            function render(time) {
              time *= 0.01;
          
              if (resizeRendererToDisplaySize(renderer)) {
                const canvas = renderer.domElement;
                camera.aspect = canvas.clientWidth / canvas.clientHeight;
                camera.updateProjectionMatrix();
              }
          
              objects.forEach((obj, ndx) => {
                const speed = .1 + ndx * .05;
                const rot = time * speed;
                obj.rotation.x = rot;
                obj.rotation.y = rot;
              });
          
              renderer.render(scene, camera);
          
              requestAnimationFrame(render);
            }
          
            requestAnimationFrame(render);
          }
          
          main();
          
    }

    render(){
        return(
            <div className="App">
            <Helmet>
                  <meta charset="utf-8" />
                  <link rel="icon" href="head.svg" />
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <meta name="theme-color" content="#000000" />
                  <meta name="description" content="Abdelatheem Maraqa || Full Stack Frontend & Backend Developer (Websites, Mobile Apps, ReactJS, React Native, PHP Laravel, SEO) +2 years of experience - 404" />
                  <link rel="apple-touch-icon" href="/head.svg" />
                  <title>404 Page Not Found | Abdelatheem Maraqa Full Stack Developer</title>
                  <link rel="canonical" href="https://abd-maraqa.com/" />
                  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w==" crossorigin="anonymous" referrerpolicy="no-referrer"></link>
              </Helmet>
              <section className="hero" id="notFound">
                <div className="container-fluid p-0">
                  <div className="row w-100">
                    <div className="col-md-12">
                      <Navbar />
                      <p className="mega">4<span className="boom">0</span>4<canvas className="bola" id="bola"></canvas></p>
                      <p className="mini">Page not found.</p>
                      <br /><br />
                    </div>
                    <div className="col-md-12 p-0">
                      <Link class="w-100" to="/"><button id="btn-challenge" className="btn-challenge"><span>Back To Home</span><small>Hover for effect</small>
                          <div className="hover-area">
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="hover-col"></div>
                              <div className="icon-home">
                                  <div className="roof roof-1"></div>
                                  <div className="roof roof-2"></div>
                                  <div className="roof-design roof-design-1"></div>
                                  <div className="roof-design roof-design-2"></div>
                                  <div className="roof-side roof-side-1"></div>
                                  <div className="roof-side roof-side-2"></div>
                                  <div className="roof-wall roof-wall-1"></div>
                                  <div className="roof-wall roof-wall-2"></div>
                                  <div className="wall wall-1"></div>
                                  <div className="wall wall-2"></div>
                                  <div className="wall wall-3"></div>
                                  <div className="wall wall-4"></div>
                                  <div className="door"></div>
                                  <div className="floor"></div>
                              </div>
                          </div>
                      </button>
                      </Link>
                      <br />
                      <br />
                      <Contact />
              
                      {/* <!-- ========== Start Footer --> */}
                      <footer className="footer">
                        <p>Copyright &copy; Abdelatheem Maraqa And Made With Love All Rights Reserved.</p>
                      </footer>
                      {/* <!-- End Footer ============== --> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
        )
    }
};

 export default NotFound;