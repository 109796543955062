
import React from 'react';
import { Link } from "react-router-dom";

function close() {
	document.getElementById('menu-icon').click();
}

function Navbar() {
    return (
        <div>
            <Link to="/" className="logo">
                <img loading="lazy" src="/head.svg" alt="Abdelatheem Maraqa Developer Logo" />
            </Link>

            <input className="menu-icon" type="checkbox" id="menu-icon" name="menu-icon"/>
            <label  className="hamburgar" htmlFor="menu-icon"></label>
            <nav className="nav"> 		
                <ul className="pt-5">
                    <li><Link to="/" onClick={close}>HOME</Link></li>
                    <li><Link to="/Works" onClick={close}>Works</Link></li>
                </ul>
            </nav>
        </div>
    );
}

export default Navbar;