
import React from 'react';

function Resume() {
    return (
    	<section className="resume bg-img bg-resume">
    		<div className="container">
    			<div className="row">
    				<div className="col-lg-3 col-sm-6 center-content">
    					<div className="item mb-md50">
    						<span className="icon fa fa-university"></span><br />
    						<h5 className="title title-left">Education</h5>
    						<ul>
    							<li>
    								<h6>The Hashemite University  <br />
										<span className="time">
											2015 - 2019
										</span>
									</h6>
    								<p>Bachelor of Computer Information Systems from The Hashemite University</p>
    							</li>
    							<li>
    								<h6>Oracle course <br />
										<span className="time">
											Jan - May 2018
										</span>
									</h6>
    								<p>Certification Oracle course in SQL, PL/SQL, Forms and Reports.</p>
    							</li>
    							<li>
    								<h6>ASP.Net MVC Course <br />
										<span className="time">
											Jun - Oct 2018
										</span>
									</h6>
    								<p>Certification ASP.Net MVC, C# total 90 hours.</p>
    							</li>
    						</ul>
    					</div>
    				</div>
    				<div className="col-lg-4 col-sm-6 center-content">
    					<div className="item mb-md50">
    						<span className="icon fa fa-briefcase"></span><br />
    						<h5 className="title title-left">Experience</h5>
    						<ul>
    							<li>
    								<h6>Full Stack Developer at <br /><b>NCIT Solutions </b>
									<span className="time">
										(Jan 2019 - Apr 2020)
									</span></h6>
    								<p>Full Stack Web And mobile apps Developer</p>
    							</li>
    							<li>
    								<h6>Part-time back-end Developer at <b>Re7nak </b> 
									<span className="time">
										(Apr 2020 - July 2020)
									</span>
									</h6>
    								<p>back-end Developer (Laravel).</p>
    							</li>
    							<li>
    								<h6>Full Stack Developer at <br /><b>O`Minus </b>  
									<span className="time">
										(Apr 2020 - Sep 2021)
									</span>
									</h6>
    								<p>Team leader and Full Stack Web And Cross-platform mobile applications Developer</p>
    							</li>
								<li>
    								<h6>Full Stack Developer at <br /><b>Al Sahab Global </b>  
									<span className="time">
										(Sep 2021 - Present)
									</span>
									</h6>
    								<p>Full Stack Web Developer</p>
    							</li>
    						</ul>
    					</div>
    				</div>
    				<div className="col-lg-5 center-content">
    					<div className="item skills">
    						<span className="icon fa fa-layer-group"></span><br />
    						<h5 className="title title-left">My Skills</h5>

    						<div className="skill">
    							<h6>HTML, CSS, SASS, Bootstrap, JAVASCRIPT, JQuery, AJAX, PHP, MYSQL  <span>90%</span></h6>
    							<div className="skills-progress"><span data-value='90%'></span></div>
    						</div>
    						<div className="skill">
    							<h6>Pure PHP, Laravel, React Native, Redux, REST API, Yii2 <span>80%</span></h6>
    							<div className="skills-progress"><span data-value='80%'></span></div>
    						</div>
    						<div className="skill">
    							<h6>ReactJS, Context API, SOAP API, SEO  <span>60%</span></h6>
    							<div className="skills-progress"><span data-value='60%'></span></div>
    						</div>
    						<div className="skill">
    							<h6>Node JS, Express <span>40%</span></h6>
    							<div className="skills-progress"><span data-value='40%'></span></div>
    						</div>

                			<div className="skill">
    							<h6>TypeScript, Wordpress  <span>20%</span></h6>
    							<div className="skills-progress"><span data-value='20%'></span></div>
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</section>
    );
}

export default Resume;