
import React from 'react';

function Services() {
    return (
        <section className="services section-padding" id="services" data-scroll-index="2">
    		<div className="container">
    			<div className="row w-100">
    				
    				<div className="section-head full-width col-md-12">
    					<h4 className="title">My Services</h4>
    				</div>

    				<div className="col-md-4">
    					<div className="item mb-md50">
							<div className="all">
								<div className="left">
									<div className="text">DATABASE</div>
								</div>
								<div className="center">
									<div className="explainer"><span>{isMobile() ? 'Click me' : 'Hover me'}</span></div>
									<div className="text">Frontend</div>
								</div>
								<div className="right">
									<div className="text">Backend</div>
								</div>
							</div>
    						<h6>Web Development</h6>
    						<p>I’ve developed tens of web apps and know what works. Stability, performance, accessibility, security.</p>
    					</div>
    				</div>
    				<div className="col-md-4 active bolt">
    					<div className="item mb-md50">
    						<div className="all">
								<div className="left1">
									<div className="text">APIs</div>
								</div>
								<div className="center1">
									<div className="explainer"><span>{isMobile() ? 'Click me' : 'Hover me'}</span></div>
									<div className="text">ReactNative</div>
								</div>
								<div className="right1">
									<div className="text">Deployment</div>
								</div>
							</div>
    						<h6>Mobile Apps</h6>
    						<p>I’ve developed many cross-platform mobile applications using React-native & Redux</p>
    					</div>
    				</div>
            		<div className="col-md-4">
    					<div className="item mb-md50">
    						<div className="all">
								<div className="left2">
									<div className="text">Modifiable</div>
								</div>
								<div className="center2">
									<div className="explainer"><span>{isMobile() ? 'Click me' : 'Hover me'}</span></div>
									<div className="text">Readable</div>
								</div>
								<div className="right2">
									<div className="text">Documented</div>
								</div>
							</div>
    						<h6>Clean Code</h6>
    						<p>Wrtie Clean code that can be read and enhanced by any other developer than me. With understandability comes readability, changeability, extensibility and maintainability.</p>
    					</div>
    				</div>
    				
    				<div className="col-md-4">
    					<div className="item mb-md50">
    						<div className="all">
								<div className="left3">
									<div className="text">Analysis</div>
								</div>
								<div className="center3">
									<div className="explainer"><span>{isMobile() ? 'Click me' : 'Hover me'}</span></div>
									<div className="text">Optimizations</div>
								</div>
								<div className="right3">
									<div className="text">Usable</div>
								</div>
							</div>
    						<h6>SEO</h6>
    						<p>Develop websites in high SEO standards. <br /> Which improve the quality and quantity of website traffic from search engines.</p>
    					</div>
    				</div>
    				<div className="col-md-4 active">
    					<div className="item mb-sm50">
                			<div className="all">
								<div className="left4">
									<div className="text">Mobile</div>
								</div>
								<div className="center4">
									<div className="explainer"><span>{isMobile() ? 'Click me' : 'Hover me'}</span></div>
									<div className="text">Desktop</div>
								</div>
								<div className="right4">
									<div className="text">Tablet</div>
								</div>
							</div>
    						<h6>Fully Responsive</h6>
    						<p>I develop websites that render well on a variety of devices and deliver the best experience to users, whether they're on their desktop, laptop, tablet, or smartphone</p>
    					</div>
    				</div>
    				<div className="col-md-4">
    					<div className="item">
    						<div className="all">
								<div className="left5">
									<div className="text">Minify</div>
								</div>
								<div className="center5">
									<div className="explainer"><span>{isMobile() ? 'Click me' : 'Hover me'}</span></div>
									<div className="text">Insights</div>
								</div>
								<div className="right5">
									<div className="text">Compress</div>
								</div>
							</div>
    						<h6>High Performance</h6>
    						<p>I improve the speed in which web pages are downloaded and displayed on the user's web browser using latest tools.</p>
    					</div>
    				</div>

    			</div>
    		</div>
    	</section>
    );
}

function isMobile() {
	try{ document.createEvent("TouchEvent"); return true; }
	catch(e){ return false; }
}

export default Services;