
import React from 'react';

function Counter() {
    return (
        <div className="numbers bg-img bg-counter" id="counter">
    		<div className="container">
    			<div className="row">
    				<div className="col-lg-3 col-md-6">
    					<div className="item mb-md50">
    						<span className="icon fa fa-laptop-house"></span>
    						<h2 className="count count1" data-value="3">0</h2>
    						<p>Companies</p>
    					</div>
    				</div>
    				<div className="col-lg-3 col-md-6">
    					<div className="item mb-md50">
    						<span className="icon fa fa-laptop-code"></span>
    						<h2 className="count count2" data-value="20">0</h2>
    						<p>Websites</p>
    					</div>
    				</div>
    				<div className="col-lg-3 col-md-6">
    					<div className="item mb-sm50">
    						<span className="icon fa fa-mobile-alt"></span>
    						<h2 className="count count3" data-value="5">0</h2>
    						<p>Mobile Applications</p>
    					</div>
    				</div>
    				<div className="col-lg-3 col-md-6">
    					<div className="item">
                <span className="icon fa fa-user-clock"></span>
    						<h2 className="count count4" data-value="2">0</h2>
    						<p>Years Experience</p>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    );
}

export default Counter;