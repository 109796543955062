import React from 'react';
import { Link } from "react-router-dom";
import '../resources/css/style.css';
import '../resources/css/plugins.css';
import {Helmet} from "react-helmet";

import Header from '../components/Header';
import About from '../components/About';
import Resume from '../components/Resume';
import Services from '../components/Services';
import Counter from '../components/Counter';
import Works from '../components/Works';
import Contact from '../components/Contact';


function close() {
	document.getElementById('menu-icon').click();
}
let enter = true;
function isMobile() {
	try{ document.createEvent("TouchEvent"); return true; }
	catch(e){ return false; }
}
class App extends React.Component {
	
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		var root = document.getElementById('portrait');

		var mouse_monitor = function(e) {
		let x = e.clientX/650;
		let y = e.clientY/560;
		
		let move_x = (x>0.5) ? '-30px' : '30px';
		let move_y = (y>0.5) ? '-20px' : '20px';
		
		root.style.setProperty("--translate-x", move_x);
		root.style.setProperty("--translate-y", move_y);
		}

		root.addEventListener("mousemove", mouse_monitor);
	}
	
	componentWillUnmount(){
		window.removeEventListener('scroll', this.handleScroll);
	}
	
	handleScroll = (event) => {
		let count1 = document.getElementsByClassName('count1')[0];
		let count2 = document.getElementsByClassName('count2')[0];
		let count3 = document.getElementsByClassName('count3')[0];
		let count4 = document.getElementsByClassName('count4')[0];
		var i = 0;
		let interval;
		
		if(isMobile()){
			if(window.pageYOffset > 5000 && window.pageYOffset < 6250 && i < 20 && i >= 0 && enter){
				enter = false;
				interval = setInterval(() => {
					i++;
					if(parseInt(count1.getAttribute("data-value")) >= i){
						count1.innerHTML = i;
					}
					if(parseInt(count2.getAttribute("data-value")) >= i){
						count2.innerHTML = i;
					}
					if(parseInt(count3.getAttribute("data-value")) >= i){
						count3.innerHTML = i;
					}
					if(parseInt(count4.getAttribute("data-value")) >= i){
						count4.innerHTML = i;
					}
					if(i > 20){
						clearInterval(interval);
					}
				}, 200);
			} else {
				clearInterval(interval);
			}
		} else {
			if(window.pageYOffset > 2500 && window.pageYOffset < 3250 && i < 20 && i >= 0 && enter){
				enter = false;
				interval = setInterval(() => {
					i++;
					if(parseInt(count1.getAttribute("data-value")) >= i){
						count1.innerHTML = i;
					}
					if(parseInt(count2.getAttribute("data-value")) >= i){
						count2.innerHTML = i;
					}
					if(parseInt(count3.getAttribute("data-value")) >= i){
						count3.innerHTML = i;
					}
					if(parseInt(count4.getAttribute("data-value")) >= i){
						count4.innerHTML = i;
					}
					if(i > 20){
						clearInterval(interval);
					}
				}, 200);
			} else {
				clearInterval(interval);
			}
		}
	}

	render(){
		return (
			<div className="App">
				<Helmet>
					<meta charset="utf-8" />
					<link rel="icon" href="head.svg" />
					<meta name="viewport" content="width=device-width, initial-scale=1" />
					<meta name="theme-color" content="#000000" />
					<meta name="description" content="Abdelatheem Maraqa || Full Stack Frontend & Backend Developer (Websites, Mobile Applications, ReactJS, React Native, PHP Laravel, SEO) +2 years of experience" />
					<link rel="apple-touch-icon" href="/head.svg" />
					<title>Abdelatheem Maraqa || Full Stack Web & Mobile Apps Developer</title>
					<link rel="canonical" href="https://abd-maraqa.com/" />
					<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w==" crossorigin="anonymous" referrerpolicy="no-referrer"></link>
				</Helmet>
				{/* <!-- ===== Start Navbar --> */}
				<Link to="/" className="logo">
					<img loading="lazy" src="/head.svg" alt="Abdelatheem Maraqa Developer Logo" />
				</Link>

				<input className="menu-icon" type="checkbox" id="menu-icon" name="menu-icon"/>
				<label className="hamburgar" htmlFor="menu-icon"></label>
				<nav className="nav"> 		
					<ul className="pt-5">
						<li><a href="#home" onClick={close}>HOME</a></li>
						<li><a href="#about" onClick={close}>About Me</a></li>
						<li><a href="#services" onClick={close}>Services</a></li>
						<li><Link to="/Works" onClick={close}>Works</Link></li>
					</ul>
				</nav>
				{/* <!-- End Navbar =============== --> */}

				<Header />
				<About />
				<Resume />
				<Services />
				<Counter />
				<Works />
				<Contact />
				
				{/* <!-- ========== Start Footer --> */}
				<footer className="footer">
					<p>Copyright &copy; Abdelatheem Maraqa And Made With Love All Rights Reserved.</p>
				</footer>
				{/* <!-- End Footer ============== --> */}
			</div>
		);
	}
}

export default App;
