
import React from 'react';

function Contact() {
    return (
        <section id="contact" className="contact section-padding bg-img bg-contact">
    		<div className="container">
    			<div className="row">    				
    				<div className="section-head full-width mb-50 col-md-12">
    					<h4 className="title">Get In Touch</h4>
    				</div>

                    <div className="info full-width col-md-12">
                    	<div className="row">
                    		<div className="col-md-4 center-content">
                    			<div className="item mb-sm50">
                    				<span className="icon fa fa-mobile-alt"></span>
                    				<div className="cont">
                    					<h6>Phone</h6>
                    					<p><a href="tel: +962795413503" target="_blank" rel="noreferrer noopener" className="text-gray">+962 79 5413 503</a></p>
                    				</div>
                    			</div>
                    		</div>
                    		<div className="col-md-4 center-content">
                    			<div className="item mb-sm50">
                    				<span className="icon fa fa-envelope-open"></span>
                    				<div className="cont">
                    					<h6>Email</h6>
                    					<p><a href="mailto: me@abd-maraqa.com" target="_blank" rel="noreferrer noopener" className="text-gray">me@abd-maraqa.com</a></p>
                    				</div>
                    			</div>
                    		</div>
                    		<div className="col-md-4 center-content">
                    			<div className="item">
                    				<span className="icon fa fa-map-marker-alt"></span>
                    				<div className="cont">
                    					<h6>Address</h6>
                    					<p className="text-gray">Amman, Jordan</p>
                    				</div>
                    			</div>
                    		</div>
                    	</div>
						<br />
						<div className="row">
                    		<div className="col-md-4 center-content">
                    			<div className="item mb-sm50">
                    				<span className="icon fab fa-linkedin"></span>
                    				<div className="cont">
                    					<h6>Linkedin</h6>
                    					<p><a href="https://www.linkedin.com/in/abdel-atheem-maraqa-346680187/" target="_blank" rel="noreferrer noopener" className="text-gray">Abdel-atheem Maraqa</a></p>
                    				</div>
                    			</div>
                    		</div>
                    		<div className="col-md-4 center-content">
                    			<div className="item mb-sm50">
                    				<span className="icon fab fa-whatsapp"></span>
                    				<div className="cont">
                    					<h6>Whatsaap</h6>
                    					<p><a href="https://www.facebook.com/abood.anas.712/" target="_blank" rel="noreferrer noopener" className="text-gray">+962 79 5413 503</a></p>
                    				</div>
                    			</div>
                    		</div>
                    		<div className="col-md-4 center-content">
                    			<div className="item">
                    				<span className="icon fab fa-facebook"></span>
                    				<div className="cont">
                    					<h6>Facebook</h6>
                    					<p><a href="https://www.facebook.com/abood.anas.712/" target="_blank" rel="noreferrer noopener" className="text-gray">Abdelatheem Maraqa</a></p>
                    				</div>
                    			</div>
                    		</div>
                    	</div>
                    </div>
    			</div>
    		</div>
    	</section>
    );
}

export default Contact;