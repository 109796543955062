
import React from 'react';

function Header() {
    return (
        <header className="header valign bg-img stars-cont" id="home">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="vertical-center full-width text-center caption">
                            <h2 className="signature-font">Abdelatheem Maraqa</h2>
                            <h1 className="cd-headline clip">
                                <span className="blc"> </span>
                                <span className="cd-words-wrapper">
                                <b className="is-visible">Full Stack Developer.</b>
                                <b>Web Developer.</b>
                                <b>Mobile Apps Developer.</b>
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="arrow">
                <a href="#about">
                    <i className="fas fa-chevron-down"></i>
                </a>
            </div>
        </header>
    );
}

export default Header;