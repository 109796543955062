
import React from 'react';
import { Link } from "react-router-dom";
import Work from './Work';

function isMobile() {
	try{ document.createEvent("TouchEvent"); return true; }
	catch(e){ return false; }
}

function Works() {
    return (
        <section className="portfolio section-padding" id="works" data-scroll-index="3">
			<div className="container">
				<div className="row">
					<div className="section-head full-width">
						<h4 className="title">My Works</h4>
					</div>
				</div>

				<div className="content">
				{isMobile() ?
					<p className="description text-center">Click a project to see details.</p>
				:
					<p className="description text-center">Hover over a project to see details.</p>
				}
					
					<Work 
						class="alwaseet"  title="Al Waseet" 
						desc="Al Waseet is one of the most popular classifieds websites that allow users to easily search for their needs or offer them for sale on the Internet." 
						link="https://kw.waseet.net/en/"  langs="Bootstrap . SASS . Yii2" icons="web"
					/>

					<Work 
						class="khazaneh"  title="Khazaneh" 
						desc="A fashion app that helps people in choosing what to wear every day from there own clothes based to occaion or the type of clothes they want to wear." 
						link=""  langs="React Native . Redux . Laravel" icons="app"
					/>

					<Work 
						class="yashfeen"  title="Yashfeen" 
						desc="A medical E-commerce website for selling medical & health care products online." 
						link="https://yashfeen.online/"  langs="Bootstrap . SASS . Laravel" icons="web"
					/>

					<Work 
						class="tacticat"  title="Tacticat" 
						desc="An application and website for the latest football news, results and tactics for the matches of the top five European leagues." 
						link="https://tacticalfootball.net/"  langs="React Native . Redux . Bootstrap . Laravel" icons="webapp"
					/>

					<Work 
						class="washup"  title="Washup" 
						desc="A laundry app where users subscribe to a package in exchange for washing a certain number of items per month." 
						link=""  langs="React Native . Redux . Laravel" icons="app"
					/>

					<Work 
						class="greenpower"  title="Jordan Green Power" 
						desc="The official site of the Energy and Environmental Sustainability Unit in the Jordan Chamber of Industry." 
						link="https://jordangreenpower.com/"  langs="Bootstrap . SASS . Laravel" icons="web"
					/>
				</div>

				<div className="col-md-12 text-center">
					<span className="buton">
						<Link to="/Works" front="View All" back="&#xf06e;"></Link>
					</span>
				</div>
			</div>
		</section>
    );
}

export default Works;