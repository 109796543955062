
import React from 'react';

function Work(props) {
    return (
        <div className="card">
            <div className={`front ${props.class}`}></div>
            <div className={`back ${props.class}`}> 
                <div>
                    <h6>{props.title}</h6>
                    <p>{props.desc}</p>
                    <span>{props.langs}</span>
                    <div>
                        {props.icons === 'web' ?
                            <i className="fa fa-globe fa-lg p-2 color-blue"></i>
                        : props.icons === 'app' ?
                            <span>
                                <i className="fab fa-google-play fa-lg p-2"></i>
                                <i className="fab fa-app-store-ios fa-lg p-2 color-blue"></i>
                            </span>
                        :
                            <span>
                                <i className="fa fa-globe fa-lg p-2 color-blue"></i>
                                <i className="fab fa-google-play fa-lg p-2"></i>
                                <i className="fab fa-app-store-ios fa-lg p-2 color-blue"></i>
                            </span>
                        }
                    </div>
                    {props.icons === 'app' ? 
                        null
                    :
                        <a href={props.link} target="_blank" rel="noreferrer noopener" className="button">Visit Website</a>
                    }
                    
                </div>
            </div>
        </div>
    );
}

export default Work;