import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import App from './views/App';
import Works from "./views/Works";
import NotFound from "./views/NotFound";
import ScrollToTop from './ScrollToTop';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <Route path="/" exact component={App} />
      <Route path="/Works" component={Works} />
      <Route path="" component={NotFound} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
